<template>
  <div class="trajectory-main">
    <div class="trajectory-main-query">
      <el-form :model="form" :rules="rules" ref="form" label-position="right"
               label-width="78px">
        <el-form-item label="车牌号" prop="deviceId">
          <el-select v-model="form.deviceId" placeholder="请选择车牌" filterable ref="select">
            <el-option
                    v-for="(item, idx) in carList"
                    :key="idx"
                    :label="item.label"
                    :value="item.device"
                    :disabled="item.deviceStatus==='无设备'">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间设置" prop="dateType">
          <el-select v-model="form.dateType"
                     placeholder="请选择"
                     @change="selectChange"
                     filterable
                     clearable>
            <el-option v-for="(item, idx) in $enums.SetDateList"
                       :label="item"
                       :value="item"
                       :key="idx"/>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" prop="startTime">
          <el-date-picker
                  v-model="form.startTime"
                  value-format="timestamp"
                  type="datetime"
                  placeholder="选择开始时间"
                  @change="clickStartTime"
                  :disabled="form.dateType!=='自定义时间'">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
          <el-date-picker
                  v-model="form.endTime"
                  value-format="timestamp"
                  type="datetime"
                  placeholder="选择结束时间"
                  @change="clickEndTime"
                  :disabled="form.dateType!=='自定义时间'">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div style="text-align: right; padding-right: 10px">
        <!--submit-->
        <el-button type="primary" @click="submit" :disabled="isDisabled">查询</el-button>
      </div>
    </div>
    <div class="trajectory-main-map">
      <div class="main-map" id="container">
        <div ref="InfoWindow" style="background: #fff; padding: 10px; display:none" :style="{display:display}" >
            <div>
                <el-row>
                    <el-col :span='6' 
                    style='display:block; 
                        text-align:justify;
                        text-align-last:justify;'>
                        接收时间
                    </el-col>
                    
                    <el-col :span='16'>
                        : {{traValue.receiveTime}}
                    </el-col>
                    <el-col :span='6' 
                    style='display:block; 
                            text-align:justify;
                            text-align-last:justify;'>
                        速度
                    </el-col>
                    
                    <el-col :span='16'>
                        : {{traValue.cs}}(km/h)
                    </el-col>
                    <el-col :span='6' 
                    style='display:block; 
                            text-align:justify;
                            text-align-last:justify;'>
                        油量
                    </el-col>
                    
                    <el-col :span='16'>
                        : {{traValue.oil}}(L)
                    </el-col>
                    <el-col :span='6' 
                    style='display:block; 
                            text-align:justify;
                            text-align-last:justify;'>
                        经纬度
                    </el-col>
                    
                    <el-col :span='16'>
                        : {{traValue.lng}},{{traValue.lat}}
                    </el-col>
                </el-row>
                
                
                
            </div>
            
        </div>
        <div class="map-control">
          <div style="text-align: center; color: #333;margin-bottom: 10px;">轨迹回放控制</div>
          <el-button size="mini" type="primary" @click="startAnimation">开始播放</el-button>
          <el-button size="mini" type="primary" @click="stopAnimation">暂停播放</el-button>
          <el-button size="mini" type="primary" style="margin-left: 0;margin-top: 10px" @click="initAnimation">继续播放
          </el-button>
          <el-button size="mini" type="primary" @click="fastAnimation">快速播放</el-button>
        </div>
      </div>
      <!-- <div class="main-table"> -->
        <!-- <div class="table-tabs">
          <div v-for="(item, idx) in ['停车点', '报警列表']" :key="idx" class="curp"
               :class="{'tabs-active': tabsActive===item}"
               @click="clickTable(item)">{{item}}
          </div>
        </div> -->
        <!-- <div class="table">
          <ayl-table :table="stopTable" v-show="tabsActive==='停车点'"/>
          <ayl-table :table="alarmTable" v-show="tabsActive==='报警列表'"/>
        </div> -->
      <!-- </div> -->
    </div>
    <!-- 查看附件 -->
    <el-dialog title="查看附件" :visible.sync="dialogFormVisible">
      <div class="attachment-main">
        <div class="left-img">
          <div style="width: 100%;height: 100%;" v-for="(item,index) in videoUrl" :key="index">
            <video :src="item" type="video/mp4" controls="controls" preload="auto" style="width: 100%" v-if="videoIdx === 1 && videoType === 'mp4'"></video>
            <ayl-ts :url='item' style="width: 100%;height: 100%;" v-if="videoIdx === 1 && videoType === 'ts'"></ayl-ts>
            <img :src="url==='' ? playerOptions.images[0] : url" class="images" v-image-preview v-else-if="videoIdx !== 1">
          </div> 
          <!-- <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" style="width: 100%"
                 v-if="videoIdx === 1"></video>
          <img :src="url===''?playerOptions.images[0]:url" class="images" v-image-preview v-else> -->
        </div>
        <div class="right-img">
          <div style="margin-bottom: 5px;" v-for="(item,index) in videoUrl" :key="index">
            <video :src="item" type="video/mp4" controls="controls" preload="auto" class="images" @click="videoItem(1)" v-if="videoType === 'mp4'"></video>
            <div style="width:100%;height: 100%" @click.stop="videoItem(1)">
              <ayl-ts :url='item' style="width: 100%;height: 100%;" v-if="videoType === 'ts'" class="images"></ayl-ts>
            </div> 
          </div>
          <!-- <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" class="images"
                 @click="videoItem(1)"></video> -->
          <img v-for="(item, idx) in playerOptions.images" :key="idx" @click="chg(idx,0)" :src="item" class="images">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // import { coordinate, stopPoint, alarmPoint } from './dataList'

  const onLineIcon = require('../../../../assets/images/map-icon (3).png')
  const _s = require('../../../../assets/images/starting.png')
  const _e = require('../../../../assets/images/end.png')
  const _st = require('../../../../assets/images/tingche.png')
  const _Ar = require('../../../../assets/images/bj.png')
  export default {
    data () {
      const vm = this
      return {
        isDisabled: false,
        // 查询数据
        carList: [],
        form: {
          deviceId: this.$route.query.deviceId || '',
          dateType: '今天',
          startTime: new Date().setHours(0, 0, 0, 0),
          endTime: new Date().setHours(23, 59, 59, 99)
        },
        rules: {
          deviceId: {required: true, message: '请选择车牌号', trigger: 'change'},
          startTime: {required: true, message: '请选择开始时间', trigger: 'change'},
          endTime: {required: true, message: '请选择结束时间', trigger: 'change'},
          dateType: {required: true, message: '请选择时间设置', trigger: 'change'},
        },
        tabsActive: '停车点',
        // 地图数据
        map: null,
        center: null,
        mapMarker: [],
        mapStopMarker: [],
        mapAlarmMarker: [],
        polyline: null,
        infoWindow: null,
        display: 'none', //信息窗体是否显示
        // 速度
        speed: null,
        speed_f: null,

        
        pathLength:0, //轨迹进行到第几个点
        traValue: {
            oil:0,
            receiveTime:'',
            cs:0,
            lng:null,
            lat:null,
        },
        pathIndex:-1,


        // 停车点列表
        stopTable: {
          hidePagination: true,
          api: vm.$api.GetStopPointList,
          // query: {
          //   deviceId: null,
          //   startTime: null,
          //   endTime: null,
          // },
          columns: [{
            title: '序号',
            width: '50px',
            $index: 'index',
          }, {
            title: '车牌号',
            key: 'plateNumber',
          }, {
            width: '90px',
            title: '驾驶员姓名',
            key: 'driverName',
          }, {
            title: '所属公司',
            key: 'carUnit',
            showTooltip: true,
          }, {
            title: '总里程',
            key: 'mileage',
          }, {
            title: '停车位置',
            key: 'position',
            showTooltip: true,
          }, {
            title: '开始时间',
            key: 'stopStartTime',
            filter: 'str2ymdhm',
            showTooltip: true,
          }, {
            title: '结束时间',
            key: 'stopEndTime',
            filter: 'str2ymdhm',
            showTooltip: true,
          }, {
            title: '停车时长',
            key: 'stopTime',
          }, {
            title: '操作',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.viewPosition.bind(this, ctx.index, '停车点')
                  }
                }, '查看位置'),
              ])
            }
          }]
        },
        // 报警点列表
        alarmTable: {
          hidePagination: true,
          api: vm.$api.LsitAlarmDeal,
          query: {
            deviceId: null,
            alarmIdentifierList: null,
            alarmType: null,
            dealStatus: null,
            dealName: null,
            startTime: null,
            endTime: null,
            queryContent: null
          },
          columns: [{
            title: '序号',
            width: '50px',
            $index: 'index',
          }, {
            title: '车牌号',
            key: 'plateNumber',
            width: '100px',
          }, {
            title: '驾驶员姓名',
            key: 'driverName',
            width: '90px',
          }, {
            title: '报警类型',
            key: 'alarmIdentifier',
            showTooltip: true,
          }, {
            title: '报警等级',
            key: 'alarmType',
            showTooltip: true,
          }, {
            title: '报警开始时间',
            key: 'alarmStartTime',
            filter: 'str2ymdhm',
            showTooltip: true,
            width: '110px',
          }, {
            title: '报警位置',
            key: 'location',
            showTooltip: true,
          }, {
            title: '处理状态',
            key: 'dealStatus',
          }, {
            title: '处理人',
            key: 'dealUserName',
          }, {
            title: '附件',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': ctx.row.attache === 1,
                  },
                  on: {
                    click: vm.goPreview.bind(this, ctx.row)
                  }
                }, ctx.row.attache === 0 ? '无附件' : '预览')
              ])
            }
          }, {
            title: '操作',
            width: '180px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.goDetail.bind(this, ctx.row)
                  }
                }, '查看详情'),
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.viewPosition.bind(this, ctx.index, '报警点')
                  }
                }, '查看位置'),
              ])
            }
          }]
        },
        coordinateList: [],
        stopPointList: [],
        alarmPointList: [],
        pointDataList: [],

        dialogFormVisible: false,
        url: '',
        videoUrl: [],
        videoType: null,
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [],
          images: [],
          poster: '', //你的封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true  //全屏按钮
          }
        },
      }
    },
    methods: {
      async submit () {
        let params = {
          deviceId: this.form.deviceId,
          startTime: this.form.startTime,
          endTime: this.form.endTime,
        }
        if(this.form.deviceId){
          this.$set(this.stopTable,"query",params)
        }
        // this.getStopTableList()
        this.$refs.form.validate(async valid => {
          if (this.mapMarker.length > 0) {
            this.map.remove(this.mapMarker)
            this.map.remove(this.polyline)
          }
          if (!valid) return
          try {
            this.isDisabled = true
            const data = await this.$api.GetDeviceHistoryUsage({
              deviceId: this.form.deviceId,
              startTime: this.form.startTime,
              endTime: this.form.endTime,
            })
            //data.result.
            this.coordinateList = data.result.lineArray
            this.pointDataList = data.result.pointDataList

            //data
            this.stopPointList = data.stopPointList
            this.alarmPointList = data.alarmPointList   
            
            this.display = 'block'

            this.initCoordinate(this.coordinateList)
            this.initStopPoint(this.stopPointList)
            this.initAlarmPoint(this.alarmPointList)

            await this.getStopTableList()
            await this.getAlarmTableList()
          } catch (e) {
            console.log(e)
          }
          setTimeout(() => {
            this.isDisabled = false
          }, 3000)
        })
      },
      // 绘制轨迹
      initCoordinate (c) {
        // 绘制起点
        this.mapMarker[0] = new AMap.Marker({
          map: this.map,
          position: c[0],
          icon: _s,
          offset: new AMap.Pixel(-26, -24),
        })
        // 绘制终点
        this.mapMarker[1] = new AMap.Marker({
          map: this.map,
          position: c[c.length - 1],
          icon: _e,
          offset: new AMap.Pixel(-26, -24),
        })
        // 绘制车辆
        this.mapMarker[2] = new AMap.Marker({
          map: this.map,
          position: c[0],
          icon: onLineIcon,
          offset: new AMap.Pixel(-10, -15),
          autoRotation: true,
          // angle: 180,
        })
        // 绘制线路
        this.polyline = new AMap.Polyline({
          map: this.map,
          path: c,
          showDir: true,
          isOutline: true,
          outlineColor: '#cccccc',
          strokeColor: '#1e9eea',
          strokeWeight: 8,
          lineJoin: 'round',
        })
        this.map.setFitView()
        // 获取轨迹的总里程
        const mileage = Math.round(this.polyline.getLength() / 1000)
        /*
        * 根据设备上报的点来计算速度
        * 正常播放：点与点间隔时间为1s；
        * 快速播放：点与点间隔时间为0.25s.
        * */
        const num = (c.length / 60 / 60).toFixed(3)
        this.speed = Math.round(mileage / num)

        const num_f = (c.length * 0.25 / 60 / 60).toFixed(3)
        this.speed_f = Math.round(mileage / num_f)

        // console.log(this.speed, this.speed_f)

        this.mapMarker[2].moveAlong(c, this.speed)
        let that = this;
        this.mapMarker[2].on('click',function(){
            that.infoWindow.open(that.map,this.getPosition())
        })
        this.infoWindow.open(this.map,this.mapMarker[2].getPosition());
        

        this.mapMarker[2].on('moving',(e)=>{
        //     // console.log(e.passedPath);
            this.infoWindow.setPosition(e.passedPath[e.passedPath.length-1])
            if(e.passedPath){
                let length = e.passedPath.length;
                
                if(length>this.pathLength){
                    this.pathIndex++;
                    while(this.pathIndex>0){
                        if(this.coordinateList[this.pathIndex].lat==this.coordinateList[this.pathIndex-1].lat&&this.coordinateList[this.pathIndex].lng==this.coordinateList[this.pathIndex-1].lng)
                            this.pathIndex++;
                        else
                            break;
                        
                    }
                    this.pathLength = length;
                    // console.log("this.pathIndex",this.pathIndex);
                    Object.keys(this.traValue).forEach(item=>{
                        this.traValue[item] = this.pointDataList[this.pathIndex][item]})
                    
                    
                }
                
                
            }
        })
        this.mapMarker[2].on('movealong',(e)=>{
        Object.keys(this.traValue).forEach(item=>{
                        this.traValue[item] = this.pointDataList[this.pointDataList.length-1][item]})
            
        })
      },
      // 绘制停车点
      initStopPoint (s) {
        this.mapStopMarker.map( v => {
          this.map.remove(v)
        })
        this.mapStopMarker = []
        s.forEach((e, i) => {
          this.mapStopMarker[i] = new AMap.Marker({
            map: this.map,
            position: e,
            icon: _st,
          })
          
        //   this.mapStopMarker[i].on("click", (e) => {
        //     //拿到点携带的数据 传递给信息窗体
        //     console.log("点击事件",e.target.getExtData())
        //     this.traValue = e.target.getExtData();

        //     // this.infoWindow.setContent(this.$refs["InfoWindow"]);
        //     // this.infoWindow.close();
        //     this.infoWindow.open(this.map, e.target.getPosition());
        //   });
        })
      },
      // 绘制报警点
      initAlarmPoint (a) {
        this.mapAlarmMarker.map( v => {
          this.map.remove(v)
        })
        this.mapAlarmMarker = []
        a.forEach((e, i) => {
          this.mapAlarmMarker[i] = new AMap.Marker({
            map: this.map,
            position: e,
            offset: new AMap.Pixel(-10, -26),
            icon: _Ar,
          })
        })
      },
      // 请求停车点
      async getStopTableList () {
        this.stopTable.query.deviceId = this.form.deviceId
        this.stopTable.query.startTime = this.form.startTime
        this.stopTable.query.endTime = this.form.endTime
        await this.$search(this.stopTable)
      },
      // 请求报警点
      async getAlarmTableList () {
        this.alarmTable.query.deviceId = this.form.deviceId
        this.alarmTable.query.startTime = this.form.startTime
        this.alarmTable.query.endTime = this.form.endTime
        this.alarmTable.query.queryContent = this.$refs.select.query.substring(0, 7)
        await this.$search(this.alarmTable)
      },
      // 开始播放
      startAnimation () {
        this.pathLength = 0;
        this.pathIndex = -1;
        this.mapMarker[2].moveAlong(this.coordinateList, this.speed)
        // this.mapMarker[2].resumeMove(coordinate, 500)
      },
      // 暂停播放
      stopAnimation () {
        this.mapMarker[2].pauseMove()
      },
      // 继续
      initAnimation () {
        this.mapMarker[2].resumeMove()
      },
      // 快速
      fastAnimation () {
          this.pathLength = 0;
          this.pathIndex = -1;
        this.mapMarker[2].moveAlong(this.coordinateList, this.speed_f)
      },
      // 查看位置
      viewPosition (i, v) {
        v === '报警点' ? Animation(this.map, this.mapAlarmMarker, i) : Animation(this.map, this.mapStopMarker, i)

        function Animation (map, markerArr, index) {
          const lng = markerArr[index].getPosition()
          map.setZoomAndCenter(14, [lng.lng, lng.lat])
          markerArr[index].setAnimation('AMAP_ANIMATION_BOUNCE')
          setTimeout(() => {
            markerArr[index].setAnimation('AMAP_ANIMATION_NONE')
          }, 3500)
        }

      },
      goDetail (val) {
        this.$router.push({
          path: '/safety-center/alarm-management/alarmDisposeDetails',
          query: {
            deviceId: val.deviceId,
            alarmStartTime: val.alarmStartTime,
            alarmType: val.alarmType,
            tenantId: val.tenantId,
            alarmName: val.alarmIdentifier
          }
        })
      },
      // 初始化地图
      initMap () {
        this.map = new AMap.Map('container', {
          center: this.center,
          resizeEnable: true,
          zoom: 13,
        })
      },
      // 初始化信息窗体
      initInfoWindow() {
          this.infoWindow = new AMap.InfoWindow({
            // isCustom: true,
            content: this.$refs["InfoWindow"],
            // closeWhenClickMap: true,
            offset:  new AMap.Pixel(0, -10),
        });
        
      },
      clickTable (e) {
        if (this.tabsActive === e) return
        this.tabsActive = e
        if (!this.form.deviceId) return
        e === '停车点' ? this.getStopTableList() : this.getAlarmTableList()
      },
      clickStartTime () {
        if (this.form.endTime && this.form.startTime && this.form.startTime > this.form.endTime) {
          this.$message.warning('开始时间不能大于结束时间')
          this.form.endTime = ''
        }
        const num = this.form.endTime - this.form.startTime
        if (this.form.endTime && this.form.startTime && Math.abs(num / (60 * 60 * 24 * 1000)) > 7) {
          this.$message.warning('选择的时间区域不可以大于7天')
          this.form.endTime = ''
        }
      },
      clickEndTime () {
        if (this.form.endTime && this.form.startTime && this.form.startTime > this.form.endTime) {
          this.$message.warning('开始时间不能大于结束时间')
          this.form.endTime = ''
          return
        }
        const num = this.form.endTime - this.form.startTime
        if (this.form.endTime && this.form.startTime && Math.abs(num / (60 * 60 * 24 * 1000)) > 7) {
          this.$message.warning('选择的时间区域不可以大于7天')
          this.form.endTime = ''
        }
      },
      //  时间设置
      selectChange (v) {
        switch (v) {
          case '自定义时间':
            this.form.startTime = ''
            this.form.endTime = ''
            break
          case '今天':
            this.form.startTime = new Date().setHours(0, 0, 0, 0)
            this.form.endTime = new Date().setHours(23, 59, 59, 99)
            break
          case '昨天':
            this.form.startTime = new Date().setTime(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 3600 * 1000 * 24)
            this.form.endTime = new Date().setTime(new Date(new Date().setHours(23, 59, 59, 99)).getTime() - 3600 * 1000 * 24)
            break
          case '最近两天':
            this.form.startTime = new Date().setTime(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 3600 * 1000 * 24 * 2)
            this.form.endTime = new Date().setHours(0, 0, 0, 0)
            break
          case '最近七天':
            this.form.startTime = new Date().setTime(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 3600 * 1000 * 24 * 7)
            this.form.endTime = new Date().setHours(0, 0, 0, 0)
            break
        }
      },
      async goPreview (val) {
        if (val.attache === 0) return
        this.dialogFormVisible = true
        let videoData = await this.$api.findFileByPrefix({
          tenantId: val.tenantId,
          attacheKey: val.attacheKey
        })
        this.playerOptions.images = videoData[0].images
        if(videoData[0].sources[0].type === 'video/mp4') {
          this.videoType = 'mp4'
          this.playerOptions.sources = videoData[0].sources
          this.videoUrl = [videoData[0].sources[0].src]
        } else {
          var _this = this
          _this.videoType = 'ts'
          _this.videoUrl = [videoData[0].sources[0].src];

        } 
        // this.playerOptions.sources = videoData[0].sources
        // this.videoUrl = this.playerOptions.sources[0].src
      },
      chg: function (ind, val) {
        this.url = this.playerOptions.images[ind]
        this.videoIdx = val
      },
      videoItem (val) {
        this.videoIdx = val
      }
    },
    async mounted () {
      // 获取后台数据的车辆列表
      const oldCarList = await this.$api.getRealTimeCarInfoList({})
      // 深度克隆，不直接引用后台的值
      const carList = this.$_.cloneDeep(oldCarList)
      // 调用递归, 获取所有公司下的车辆
      this.carList = this.$utils.getTreeAllData(carList)
      this.initMap()
      this.initInfoWindow();
    }
  }
</script>

<style lang='sass' scoped>

  .trajectory-main
    width: 100%
    height: 100%
    padding-top: 6px

    & > div
      display: inline-block
      height: 100%
      vertical-align: top

    &-query
      width: 330px
      padding-top: 15px

      /deep/ .el-date-editor.el-input,
      /deep/ .el-select
        width: 242px

    &-map
      width: calc(100% - 330px)

      & > div
        width: 100%

      .main-map
        height: calc(100% - 53px)
        position: relative

        .map-control
          position: absolute
          bottom: 10px
          right: 10px
          z-index: 2
          width: 200px
          height: 126px
          background: rgba(255, 255, 255, 1)
          box-shadow: 0 1px 5px 0 rgba(8, 88, 169, 0.3)
          border-radius: 4px
          padding: 15px 15px 14px

      .main-table
        margin-top: 10px
        height: calc(100% - 482px - 10px)

        .table-tabs
          .tabs-active
            color: $-color-primary-2

          div
            display: inline-block
            padding: 10px 14px
            color: $-font-color-2
            position: relative
            font-size: $fontX

            &:hover
              color: $-color-primary-2

            & + div:after
              content: ' '
              position: absolute
              left: 0
              width: 1px
              height: 16px
              background-color: $grayShallow3

        .table
          height: calc(100% - 38px)

    /deep/ .el-form-item
      width: 100%

    .attachment-main
      width: 100%

      .right-img
        width: 20%
        height: 520px
        display: inline-block
        vertical-align: top
        margin-left: 2%
        overflow-y: auto

        .images
          width: 100%

      .left-img
        width: 78%
        height: 520px
        display: inline-block

        .images
          width: 100%
          height: 100%

      .class
        display: block

      .show
        display: none
</style>
